import axios from 'axios'
import { Notify } from 'vant';
import isWeiXin from 'util';
// const baseURL = 'http://47.98.213.13:8200/blade-party-admin' // 本地启动

const instance = axios.create({
  // baseURL,
  timeout: 5000
})
// instance.defaults.timeout = 6000
// 请求拦截
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('Blade-Auth-Token');
  const url = config.url
  if (token && url.indexOf('/auth/login') < 0) {
    config.headers['Blade-Auth'] = token
  }
  if(isWeiXin){
    config.headers['User-Type'] = 'weiXin';
  }else{
    config.headers['User-Type'] = 'DINGTALK';
  }
  
  config.data = {
    ...config.data
  }
  if (config.method == 'GET') {
    config.params = {
      ...config.params
    }
  }
  config.data = {
    ...config.data
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (res) {
  if (res.data.code === 401) {
    Notify({ type: 'danger', message: res.data.message })
  }
  if (res.data.code === 400) {
    Notify({ type: 'danger', message: res.data.msg })
  }
  return res.data
}, function (error) {
  const errorPromise = Promise.reject(error)
  let b = errorPromise.catch(error => {
    Notify({ type: 'warning', message: error.response.data.msg });
  })
  if (error == 'Error: timeout of 5000ms exceeded') {
    Notify({ type: 'danger', message: '连接超时' })
  }
  return Promise.reject(error)
})
export default instance
